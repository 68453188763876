<template>
  <div id="login-page">
    <div class="login-wrapper">
      <div class="login-form">
        <div class="container">
          <div class="wrapper">
            <div class="google-signin-button">
              <button @click="signInWithGoogle">
                <img src="/login-google.svg" alt="Google Icon" /> Continue with
                Google
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="login-wallpaper"></div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      loggingIn: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    validateForm() {
      this.loggingIn = true;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
        } else {
        }
      });
    },
    checkValidLogin() {
      return new Promise((resolve, reject) => {
        let token = this.$getUserToken();
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/admin/check-admin`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            resolve(false);
          });
      });
    },
    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      const auth = getAuth();
      auth.useDeviceLanguage();

      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          return signInWithPopup(auth, provider);
        })
        .then(async (result) => {
          const user = result.user;
          const isValidAdmin = await this.checkValidLogin();
          if (isValidAdmin) {
            this.$router.push("/dashboard");
          } else {
            this.logout();
          }
        })
        .catch((error) => {
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {})
        .catch((error) => {
          console.log("ERROR SIGNING OUT!");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.brand-logo {
  align-items: center;

  img {
    max-width: 35px;
  }

  h2 {
    margin: 0;
  }
}
</style>
